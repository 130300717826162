import { Switch, Router } from "react-router";
import RouteWrapper from "./RouterWrapper";
import Home from "../containers/Home/Home";
import Video from "../containers/Video/Video";
import MainLayout from "../layouts/MainLayout/MainLayout";
import { createBrowserHistory } from "history";
import Assessment from "../containers/Assessment/Assessment";
import Profile from "../containers/Profile/Profile";
import Privacy from "../containers/Privacy/privacy";

const urlLocations = {
  profile: "/",
  privacy: "/privacy",
  videosList: "/project/:projectID",
  video: "/video/:projectID/:videoIndex",
  assessment: "/assessment/:projectID/:videoIndex",
};

const emailParam = (email) => email ? `?email=${email}` : ''

export const buildPath = {
  profile: () => `/`,
  video: (projectId, videoIndex, email) =>
    `/video/${projectId}/${videoIndex}${emailParam(email)}`,
  assessment: (projectId, videoIndex, email) =>
    `/assessment/${projectId}/${videoIndex}${emailParam(email)}`,
  videosList: (projectId, email) => `/project/${projectId}${emailParam(email)}`,
};

const history = createBrowserHistory();

export default () => (
  <Router history={history}>
    <Switch>
      <RouteWrapper
        path={urlLocations.privacy}
        layout={Privacy}
        component={{}}
      />
      <RouteWrapper
        path={urlLocations.videosList}
        layout={MainLayout}
        component={Home}
      />
      <RouteWrapper
        path={urlLocations.video}
        layout={MainLayout}
        component={Video}
      />
      <RouteWrapper
        path={urlLocations.assessment}
        layout={MainLayout}
        component={Assessment}
      />
      <RouteWrapper
        path={urlLocations.profile}
        layout={MainLayout}
        component={Profile}
      />
    </Switch>
  </Router>
);

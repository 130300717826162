import React, { createRef, useState, useEffect } from "react";
import Styled from "./styled";
import PauseIcon from "../../assets/pauseIcon.png";
import PlayIcon from "../../assets/playIcon.png";
import { buildAPIPath } from "../../constants/constants";

const VideoPlayer = ({ thumbnail, material, videoId: video, email: user }) => {
  const [isPlaying, setStatus] = useState(false);
  const [progress, setProgress] = useState(null);
  const videoRef = createRef();

  const submitVideoProgress = async (progressSeconds) => {
    const URL = buildAPIPath.watchProgress();
    await fetch(URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        user,
        video,
        progressSeconds,
      }),
    });
  };

  useEffect(() => {
    if (isPlaying) {
      setTimeout(() => {
        const currentTime = parseInt(videoRef?.current?.currentTime);
        setProgress(currentTime);
        currentTime && submitVideoProgress(currentTime);
      }, 1000);
    }
  }, [isPlaying, progress]);

  const onClick = () => {
    setStatus(!isPlaying);
    if (isPlaying) {
      videoRef.current.pause();
      return;
    }
    videoRef.current.play();
  };

  return (
    <Styled.VideoWrapper>
      <Styled.ControlIcon onClick={onClick}>
        <img src={isPlaying ? PauseIcon : PlayIcon} alt="" />
      </Styled.ControlIcon>
      <video
          onPlay={() => setStatus(true)}
          onPause={() => setStatus(false)}
          ref={videoRef} src={material?.url}
          controls={true}
          poster={thumbnail?.url}
      >
        <source type={material?.mime} src={material?.url} />
      </video>
    </Styled.VideoWrapper>
  );
};

export default VideoPlayer;

import React from "react";
import {useParams} from "react-router";
import VideoAssessment from "../Video/Video.Assessment/Video.Assessment";
import {buildPath} from "../../router";
import Styled from "./styled";
import {ReactComponent as BackIcon} from "../../assets/arrowIcon.svg";
import {useProjectStore} from "../../store/projectStore";
import {useQuery} from "../../constants/useQuery";

const Assessment = () => {
  const {videoIndex} = useParams();

  const {videos, project} = useProjectStore();
  const video = videos?.[videoIndex];

  const searchParams = useQuery();
  const email = searchParams.get("email");

  const {assessment} = video || {};

  return (
    <div className={"md:px-[90px] md:py-[50px] p-[24px] bg-white rounded-lg shadow"}>
      <Styled.BackLink to={buildPath.video(project?.id, videoIndex, email)}>
        <BackIcon/>
        Back
      </Styled.BackLink>
      <Styled.Title>
        Assessment
      </Styled.Title>
      <VideoAssessment assessment={assessment}/>
    </div>
  );
};

export default Assessment;

import styled from "styled-components";
import Input from "../../components/Input/Input";
import { Link } from 'react-router-dom';
import { MEDIA } from "../../constants/constants";
import Button from "../../components/Button/Button";

const Container = styled.div`
  max-width: 1360px;
  padding: 0 15px;
  margin: 0 auto;
`;

const Main = styled.div`
  background: #fff;
`;

const Header = styled.div`
  padding: 32px 40px;
  border-bottom: 1px solid #7a00e633;
  ${MEDIA.md} {
    display: flex;
  }
  ${MEDIA.smAndLess} {
    padding: 12px;
  }
`;

const HeaderLogo = styled(Link)`
  display: inline-block;
  margin-right: 24px;
  ${MEDIA.mdAndLess} {
    margin: 0 0 16px;
  }
`;

const HeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  ${MEDIA.smAndLess} {
    flex-direction: column;
  }
`;

const EmailInput = styled(Input)`
  width: 350px;
  margin-right: 24px;
  ${MEDIA.smAndLess} {
    flex-direction: column;
  }
  ${MEDIA.xs} {
    display: block;
    margin: 0;
    //width: 100%;
  }
`;

const Content = styled.div`
  padding: 80px 0;
`;

const Spinner = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  background: rgba(255, 255, 255, 0.5);
  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
  img {
    opacity: 0.7;
    width: 50px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    animation: rotation 1.5s infinite;
  }
`;

const EmptyState = styled.div`
  font-size: 20px;
  text-align: center;
  img {
    width: 180px;
  }
`;

const HeaderButton = styled(Button)`
  ${MEDIA.xs} {
    width: 100%;
    margin-top: 16px;
    text-align: center;
  }
`;

export default {
  Container,
  Main,
  Header,
  HeaderLogo,
  HeaderRow,
  EmailInput,
  Content,
  Spinner,
  EmptyState,
  HeaderButton,
};

import styled from "styled-components";
import { Link } from "react-router-dom";
import { MEDIA } from "../../constants/constants";

const List = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 30px;
  row-gap:40px;
  justify-content: space-evenly;
  ${MEDIA.mdAndLess} {
    text-align: center;
    justify-content: center;
  }
`;

const Card = styled(Link)`
  text-decoration: none;
  background: white;
  border-radius: 8px;
  width: 30%;
  box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;
  &:hover {
    box-shadow: rgba(0, 0, 0, 0.35) 0 5px 15px;
  }
  ${MEDIA.mdAndLess} {
    width: 45%;
  }
  ${MEDIA.xs} {
    width: 100%;
  }
`;

const CardThumbnail = styled.div`
  height: 246px;
  margin-bottom: 16px;
  border-radius: 8px;
  position: relative;
`;

const CardTitle = styled.div`
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 24px;
  text-align: center;
  color: #000;
  padding: 0px 16px;
`;

const CourseTitle = styled.div`
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 24px;
  color: #000;
  ${MEDIA.mdAndLess} {
    text-align: center;
  }
`;

const ControlIcon = styled.div`
  position: absolute;
  left: 50%;
  top: 55%;
  transform: translate(-50%, -50%);
  width: 80px;
  height: 80px;
  border-radius: 50%;
  z-index: 1;
  background: #fff;
  cursor: pointer;
  //transition: 0.3s opacity, 0.3s top;
  img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    max-width: 40px;
  }
`;

export default {
  List,
  Card,
  CardThumbnail,
  ControlIcon,
  CardTitle,
  CourseTitle,
};

import { useMemo } from "react";
import { countries } from "countries-list";
import Select, { IndicatorsContainerProps } from "react-select";
import { twMerge } from "tailwind-merge";
import { DownOutlined, UpOutlined } from "@ant-design/icons";

type CountrySelectProps = {
  value: string;
  onChange: (value: string | null) => void;
  className?: string;
};

export type CountrySelectOption = {
  value: string;
  label: string;
  code: string;
};

const CountrySelect = ({ value, onChange, className }: CountrySelectProps) => {
  const options = useMemo(() => Object.entries(countries).map(([code, value]) => ({ code, label: `${value.emoji} ${value.name}`, value: value.name })), []);
  const selectedOption = useMemo(() => options.find((option) => option.value === value), [value]);

  return (
    <Select<CountrySelectOption>
      components={{
        IndicatorSeparator,
        IndicatorsContainer,
      }}
      classNames={{ control: () => twMerge("px-4 rounded-[3px] border border-zinc-200 flex justify-between items-center cursor-pointer", className) }}
      options={options}
      value={selectedOption}
      onChange={(selectedOption) => onChange(selectedOption?.value || null)}
    />
  );
};

const IndicatorSeparator = () => null;

const IndicatorsContainer = ({ selectProps }: IndicatorsContainerProps<CountrySelectOption>) => (selectProps.menuIsOpen ? <UpOutlined /> : <DownOutlined />);

export default CountrySelect;

import React from "react";
import Styled from "./styled";

const Input = ({ input, label, prefix, ...props }) => (
  <Styled.Wrapper>
    <Styled.Input prefix={prefix} {...input} {...props} />
    {label && <Styled.Label prefix={prefix}>{label}</Styled.Label>}
    {prefix && <Styled.Prefix>{prefix}</Styled.Prefix>}
  </Styled.Wrapper>
);

export default Input;

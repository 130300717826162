import styled, { css } from "styled-components";
import { MEDIA } from "../../../constants/constants";
import Button from "../../../components/Button/Button";

const OPTION_STATUS = {
  hasResult: css`
    color: rgba(0, 0, 0, 0.5);
    pointer-events: none;
  `,
  correctAnswer: css`
    background: #f9f7fb;
    border: 1px solid #16a513;
    color: #000000;
    pointer-events: none;
  `,
  correctOption: css`
    color: rgba(0, 0, 0, 0.5);
    border: 1px solid #16a51380;
    pointer-events: none;
  `,
  incorrectAnswer: css`
    background: #fff7f7;
    border: 1px solid #ff8989;
    pointer-events: none;
    text-decoration-line: line-through;
  `,
};

const CHECKBOX_STATUS = {
  incorrectAnswer: css`
    border-color: #ff8989;
    &:after {
      background-color: #ff8989;
    }
  `,
  correctOption: css`
    border-color: #16a51380;
    &:after {
      display: none;
    }
  `,
  correctAnswer: css`
    border-color: #16a513;
    &:after {
      background-color: #16a513;
    }
  `,
};

const List = styled.div`
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 24px;
  &:last-child {
    border: none;
  }
`;

const Question = styled.div`
  font-size: 18px;
  font-weight: 900;
  margin-bottom: 24px;
`;

const Options = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 24px;
  margin-bottom: 24px;
`;

const Option = styled.div`
  display: flex;
  flex: 0 0 calc(50% - 12px);
  padding: 15px;
  border: 1px solid #e4e4e4;
  border-radius: 30px;
  cursor: pointer;
  font-weight: 500;
  ${MEDIA.mdAndLess} {
    flex: 0 0 100%;
  }
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.8;
      pointer-events: none;
    `}
  ${({ selected }) =>
    selected &&
    css`
      border-color: #2563eb;
      background: #f9f7fb;
    `}
  ${({ status }) => {
    return !!status && OPTION_STATUS[status];
  }}
`;

const CheckBox = styled.div`
  position: relative;
  flex: 0 0 26px;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  border: 1px solid #e4e4e4;
  margin-right: 8px;
  margin-top: -2px;
  ${({ selected }) =>
    selected &&
    css`
      border: 2px solid #2563eb;
      &:after {
        content: "";
        position: absolute;
        top: 5px;
        left: 5px;
        right: 5px;
        bottom: 5px;
        background: #2563eb;
        border-radius: 50%;
      }
    `}
  ${({ status }) => {
    return !!status && CHECKBOX_STATUS?.[status];
  }}
`;

const OptionIndex = styled.div`
  display: inline-block;
  margin-right: 5px;
`;

const Wrapper = styled.div`
  max-width: 100%;
  ${MEDIA.mdAndLess} {
    max-width: 100%;
  }
`;

const SubmitButton = styled(Button)`
  ${MEDIA.xs} {
    width: 100%;
    text-align: center;
  }
`;

export default {
  List,
  Question,
  Options,
  Option,
  CheckBox,
  OptionIndex,
  Wrapper,
  SubmitButton,
};

import React, { useState } from "react";
import Styled from "./styled";
import { getStatus } from "./utils";
import VideoResult from "../Video.Result/Video.Result";
import { ReactComponent as BackIcon } from "../../../assets/arrowIcon.svg";
import {useQuery} from "../../../constants/useQuery";
import {useAssessmentStore} from "../../../store/useAssessmentStore";

const VideoAssessment = ({ assessment }) => {
  const [answers, setAnswers] = useState({});
  const { submitAnswers, result } = useAssessmentStore();
  const { gradable } = assessment || {};

  const searchParams = useQuery();
  const email = searchParams.get("email");

  return (
    <Styled.Wrapper>
      {assessment?.questions?.map((question, i) => (
        <Styled.List key={question?.id}>
          <Styled.Question>{question?.title}</Styled.Question>
          <Styled.Options>
            {question?.options?.map(({ option, id }, index) => (
              <Styled.Option
                disabled={!!result?.answersDetails}
                status={
                  gradable &&
                  getStatus(
                    question?.id,
                    `${id}`,
                    result?.answersDetails?.[question?.id],
                    answers
                  )
                }
                onClick={() =>
                  setAnswers({
                    ...answers,
                    [question?.id]: `${id}`,
                  })
                }
                selected={answers[question?.id] === `${id}`}
                key={id}
              >
                <Styled.CheckBox
                  status={
                    gradable &&
                    getStatus(
                      question?.id,
                      `${id}`,
                      result?.answersDetails?.[question?.id],
                      answers
                    )
                  }
                  selected={answers[question?.id] === `${id}`}
                />
                <div>
                  <Styled.OptionIndex>
                    {(parseInt(index) + 10).toString(36).toUpperCase()}
                    {". "}
                  </Styled.OptionIndex>
                  {option}
                </div>
              </Styled.Option>
            ))}
          </Styled.Options>
        </Styled.List>
      ))}

      {result ? (
        <VideoResult gradable={gradable} result={result} />
      ) : (
        <div className={"flex w-full place-content-center"}>
        <button
          className="h-14 px-[70px]
           py-4 bg-gradient-to-r from-blue-600 to-blue-400 rounded-[3px] justify-center items-center gap-2.5 inline-flex text-white text-[15px] font-bold"
          onClick={() =>
            submitAnswers({
              user: email,
              assessment: assessment?.id,
              answers,
            })
          }
        >
          Submit
          <BackIcon />
        </button>
        </div>
      )}
    </Styled.Wrapper>
  );
};

export default VideoAssessment;

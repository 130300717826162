import React, {createContext, useEffect} from "react";
import Styled from "./styled";
import Spinner from "../../assets/spin.svg";
import { twMerge } from "tailwind-merge";
import {useProjectStore} from "../../store/projectStore";
import enterPhoto from "../../assets/enter-photo.png";
import image1 from "../../assets/image1.png";
import image2 from "../../assets/image2.png";

const MainLayout = ({ children }) => {

  const projectKey = window.location.hostname.split('.')[0];

  const {isLoading, project, videos, getProject, getVideos} = useProjectStore();
  const logoUrl = project?.logo.url;

  useEffect(() => {
    if (!projectKey) return;
    if(!project) {
      getProject(projectKey);
    }
  }, [projectKey]);

  useEffect(() => {
    if(project && (!videos || videos?.length === 0)) {
      getVideos(project.id)
    }
  }, [project]);

  return (
    <>
      {isLoading && (
        <Styled.Spinner>
          <img src={Spinner} alt="" />
        </Styled.Spinner>
      )}
      <div className={"bg-white"}>
        <header className="h-[54px] px-[16px] py-[24px] flex md:justify-center justify-start items-center">
          {logoUrl && <img src={logoUrl} className={"mr-[14px] w-[80px]"} alt={"logo"} />}
          <div className="opacity-30 text-black text-[13px] font-light uppercase flex items-center">
            co-supplier <div className="w-px h-[15px] bg-zinc-200 ml-[6px] mr-[7px]" />
          </div>
          <img src={image1} className={"mr-[5px]"} />
          <img src={image2} />
        </header>
        <div className={"relative"}>
          <img src={enterPhoto} className={"h-[215px] block w-full object-cover absolute -z-1"} />
          <div className={"absolute w-full"}>
            <div className={"w-[1162px] md:mx-auto max-w-full px-[16px]"}>
              <div className={twMerge("text-white text-[30px] md:text-[40px] font-medium mt-[65px] text-center", !project?.name && "invisible")}>
                {project?.name || "OTB Portal"}
              </div>
              <div className={"mt-[40px]"}>{children}</div>
              <div className="opacity-20 text-black text-xs font-medium text-center mb-[48px] mt-[44px]">© 2023 OTB Life Science & Technology Consultancy FZ LLC</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MainLayout;

import React from 'react';
import { Parser } from 'html-to-react'

const Privacy = () => {

  const rawHTML = `
   <div>
      <p style="padding-top: 4pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">This Privacy Statement
        explains how OTB Life Science &amp; Technology Consultancy FZ LLC.,</p>
      <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;"><a
        href="https://www.gilead.com/privacy/privacy-statement" className="a" target="_blank">including its wholly owned
        subsidiaries and successors (together referred to as &quot;OTB Life Science&quot;), handles Personal Information
        (information that can identify you) that you provide to OTB Life Science, or that is otherwise collected, on
        websites
        controlled by OTB Life Science (together referred to as &quot;OTB Life Science Website(s)&quot;). This Website
        Privacy
        Statement is to be read in conjunction with the </a><a href="https://www.gilead.com/privacy/privacy-statement"
                                                               className="s1" target="_blank">OTB Life Science Privacy</a><a
        href="https://www.gilead.com/privacy/privacy-statement"
        style=" color: #5C89C2; font-family:Arial, sans-serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 9pt;"
        target="_blank"> </a><span
        style=" color: #5C89C2; font-family:Arial, sans-serif; font-style: normal; font-weight: normal; text-decoration: underline; font-size: 9pt;">Statement</span>.
        The OTB Life Science Privacy Statement applies to all Personal Information received and processed by OTB Life
        Science.
        This Website Privacy Statement provides further detail on Personal Information collected on websites controlled by
        OTB
        Life Science. In the event of a conflict, the OTB Life</p>
      <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">Science Privacy Statement governs.</p>
      <p style="text-indent: 0pt;text-align: left;"><br/></p>
      <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">Please read this Website Privacy Statement and the
        OTB
        Life Science Privacy Statement before using OTB Life Science Websites or submitting information to OTB Life
        Science
        via a website. By accessing and using OTB Life Science Website(s), you agree and consent to the collection, use
        and
        disclosure of your Personal Information as outlined in this Website Privacy Statement.</p>
      <p style="text-indent: 0pt;text-align: left;"><br/></p>
      <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">Unless otherwise specifically noted, this Website
        Privacy Statement does not apply to information collected from you offline, or to third-party websites to which
        OTB
        Life Science Websites may link, but that OTB Life Science does not control. You are encouraged to review the terms
        of
        the individual privacy policies of each website you visit.</p>
      <p style="text-indent: 0pt;text-align: left;"><br/></p>
      <p className="s3" style="padding-left: 5pt;text-indent: 0pt;text-align: left;">How does OTB Life Science collect
        information online?</p>
      <p style="padding-top: 9pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">In addition to the means set out in
        the
        OTB Life Science Privacy Statement, OTB Life Science Websites may collect information that could be potentially
        personally identifiable about your visits without you actively submitting such information. Unidentified
        information
        may be collected using various technologies, such as cookies and web beacons. Cookies are small text files that
        are
        transferred to your computer&#39;s hard disk by a website. Web beacons (also referred to as GIF files, pixels, or
        Internet tags) help OTB Life Science understand how you navigate around the OTB Life Science Websites. As part of
        your
        use of the site, your Internet browser automatically transmits to OTB Life Science Websites some of this
        unidentified
        information, such as the URL of the website you just visited and the browser version your computer is operating.
        Passive information collection technologies can make your use of OTB Life Science Websites easier by allowing OTB
        Life
        Science to provide better service, customize OTB Life Science Websites based on consumer preferences, compile
        statistics, analyze trends, and otherwise administer and improve OTB Life Science Websites. Certain features of
        OTB
        Life Science Websites may not work without use of passive information collection technologies. Information
        collected
        by these technologies cannot be used to identify you without additional information. With respect to limiting or
        disabling tracking technology please see section below entitled, &quot;What choices do I have about how OTB Life
        Science Websites collect and use Personal Information about me?&quot;</p>
      <p style="text-indent: 0pt;text-align: left;"><br/></p>
      <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">Some of our business partners, with whom OTB Life
        Science contracts to carry out business activities (e.g., website providers), may use their cookies on OTB Life
        Science Websites. Although OTB Life Science may not have direct access to or control over such cookies, this
        Website
        Privacy Statement governs the use of cookies by OTB Life Science and such business partners on OTB Life Science
        Websites. OTB Life Science may also allow social media companies (e.g., Facebook) to put “widgets” on OTB Life
        Science
        Websites.</p>
      <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">These third-party tools may also be used to track
        you
        across websites. For example, so long as you are</p>
      <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">logged in to Facebook, every time you land on a
        webpage
        that has a Facebook widget, Facebook will know you are on that webpage. OTB Life Science does not control the
        privacy
        practices of these third parties.</p>
      <p style="text-indent: 0pt;text-align: left;"><br/></p>
      <p className="s3" style="padding-left: 5pt;text-indent: 0pt;text-align: left;">In what circumstances and for what
        purposes might OTB Life Science collect and use Personal Information through OTB Life Science Websites?</p>
      <p style="padding-top: 9pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">In addition to what is set out in
        the
        OTB Life Science Privacy Statement, if you choose to use email page feature to receive e-mail alerts, OTB Life
        Science
        will send you an e-mail to validate your registration and confirm that you are signing up to receive such e-mails.
        If
        you no longer wish to receive such e-mails, you may follow the instructions provided in the e-mail in order to
        request
        that OTB Life Science stop sending you e-mail alerts.</p>
      <p style="padding-top: 4pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">As allowed by applicable local
        laws,
        some OTB Life Science Websites include the opportunity to send another individual a link to a OTB Life Science
        webpage. If you choose to use e-mail page feature to send a link to a OTB Life Science page, OTB Life Science will
        ask
        you for the recipient&#39;s email address. OTB Life</p>
      <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">Science will automatically send the recipient a
        one-time
        email inviting him or her to read the featured page. This information is used only for the delivery of this
        specific
        email message, and is not stored or used for any other purpose.</p>
      <p style="text-indent: 0pt;text-align: left;"><br/></p>
      <p className="s3" style="padding-left: 5pt;text-indent: 0pt;text-align: left;">What choices do I have about how OTB
        Life
        Science Websites collect and use Personal Information about me?</p>
      <p style="padding-top: 9pt;padding-left: 5pt;text-indent: 0pt;text-align: justify;">You may always limit the amount
        and
        type of Personal Information that OTB Life Science receives about you by choosing not to enter Personal
        Information
        into forms or data fields on OTB Life Science Websites. Some of OTB’s online services can only be provided to you
        if
        you provide OTB Life Science with appropriate</p>
      <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">Personal Information. Various OTB Life Science
        Websites may ask whether you wish to opt-in to its contact lists or programs for offers, promotions, and
        additional
        services that may be of interest to you.</p>
      <p style="text-indent: 0pt;text-align: left;"><br/></p>
      <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">You may also be provided with preference questions
        or
        preference boxes allowing you to indicate that you do not want OTB Life Science Websites to use tracking
        technologies,
        such as cookies, to &quot;remember&quot; your Personal Information, such as user IDs or mailing addresses, on
        return
        visits. However, OTB Life Science Websites that use tracking technologies to collect unidentified information do
        not
        generally provide you with the ability to opt-out of the tracking technologies. Some Internet browsers allow you
        to
        limit or disable the use of tracking technologies that collect unidentified information, such as a “Do Not Track”
        (“DNT”) setting. Currently, the OTB Life Science Websites are not compatible with the DNT standard in its current
        release. However, you should remember that OTB Life Science does not collect or disclose your browsing behavior
        for
        third-party marketing purposes.</p>
      <p style="text-indent: 0pt;text-align: left;"><br/></p>
      <p className="s3" style="padding-left: 5pt;text-indent: 0pt;text-align: left;">How does OTB Life Science secure
        Personal
        Information collected on OTB Life Science Websites?</p>
      <p style="padding-top: 6pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">It is OTB’s practice to ensure that
        OTB
        Life Science Websites are secured using commercially reasonable technical, procedural, and administrative
        safeguards
        proportional to the sensitivity and volume of data processed. However, the confidentiality of Personal Information
        transmitted over the Internet cannot be guaranteed. OTB Life Science urges you to exercise caution when
        transmitting
        Personal Information over the Internet. OTB Life Science cannot absolutely guarantee that unauthorized third
        parties
        will not gain access to your Personal Information; therefore, when submitting Personal Information to OTB Life
        Science
        Websites, you must weigh both the benefits and the risks. OTB Life Science has no control over third-party
        websites or
        their content, and OTB Life Science’s Website Privacy Statement does not apply to such</p>
      <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">websites. You should check the privacy policies of
        third-party websites before submitting Personal Information. This also applies to those business partners or
        social
        media services that have sharing tools on OTB Life Science Websites (e.g. Facebook). As OTB Life Science does not
        control the privacy practices of these third parties, you should review the privacy settings you have set up
        directly
        with them.</p>
      <p style="text-indent: 0pt;text-align: left;"><br/></p>
      <p className="s3" style="padding-left: 5pt;text-indent: 0pt;text-align: left;">How does OTB Life Science protect the
        privacy of children on OTB Life Science Websites?</p>
      <p style="padding-top: 9pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">No OTB Life Science websites are
        intended to collect or use any Personal Information from children on OTB Life Science Websites
        (&quot;children&quot; are individuals who have not reached the age of majority in their residential
        jurisdictions).
        OTB Life Science does not knowingly allow children to order OTB Life Science’s products or services, communicate
        with
        OTB, or use any of OTB&#39;s online services. Should you suspect that a child of whom you are the parent or legal
        guardian has provided us with Personal Information, please contact OTB Life Science using one of the methods
        specified
        below and OTB Life Science will work with you to address this issue.</p>
      <p style="text-indent: 0pt;text-align: left;"><br/></p>
      <p className="s3" style="padding-left: 5pt;text-indent: 0pt;text-align: left;">Your rights and contact
        information</p>
      <p style="padding-top: 9pt;padding-left: 5pt;text-indent: 0pt;text-align: justify;">Where individuals have rights
        under
        laws applicable to them and upon written request, OTB Life Science will grant individuals’ access to Personal Data
        that it holds about them, subject to any legal restrictions. In</p>
      <p style="padding-top: 4pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">addition, OTB Life Science will
        permit
        individuals to correct, amend, or delete information that is demonstrated to be inaccurate or incomplete or to
        object
        to certain types of processing of such information or to data portability, in certain circumstances and subject to
        certain exceptions provided by law. OTB Life Science may not be able to comply with a request where Personal Data
        has
        been destroyed, erased or made anonymous in accordance with OTB’s record retention obligations and practices. In
        the
        event that OTB Life Science cannot provide an individual with access to his/her Personal Data, OTB Life Science
        will</p>
      <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;"><a href="mailto:info@OTBLifeScience.com"
                                                                            className="a" target="_blank">endeavor to
        provide
        the individual with an explanation, subject to any legal or regulatory restrictions. Please notify OTB Life
        Science of
        your wishes by contacting OTB Life Science by telephone at +971 522732227 or </a>info@OTBLifeScience.com.
        Alternatively, letters may be sent to the following address:</p>
      <p style="text-indent: 0pt;text-align: left;"><br/></p>
      <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">OTB Life Science Attn: Privacy</p>
      <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">Makateb Building 2 – office 605</p>
      <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">Dubai Production City, Dubai, UAE</p>
      <p style="text-indent: 0pt;text-align: left;"><br/></p>
      <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">All communications to OTB Life Science should
        include
        the sender’s name and contact information (such as e-mail address, phone number or mailing address), and a
        detailed
        explanation of the request. In addition, communications related to OTB Life Science Websites should include, as
        applicable, the e-mail address used for registration and the OTB Life Science Website address on which Personal
        Information was provided. E-mail requests to delete, amend, or correct Personal Information should
        include &quot;Deletion</p>
      <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">Request&quot; or &quot;Amendment/Correction
        Request&quot;, as applicable, in the subject line of the e-mail. OTB Life</p>
      <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;"><a href="mailto:privacy@gilead.com" className="a"
                                                                         target="_blank">Science will endeavor to respond
        to
        all reasonable requests in a timely manner, and in any case, within any time limits prescribed by applicable local
        law. If you have questions or complaints regarding our privacy policy or practices, please contact us at </a>info@OTBLifeScience.com
        or at the mailing address provided above.</p>
      <p style="text-indent: 0pt;text-align: left;"><br/></p>
      <p className="s3" style="padding-left: 5pt;text-indent: 0pt;text-align: left;">Enforcement</p>
      <p style="padding-top: 9pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">OTB Life Science will investigate
        and
        attempt to resolve any complaints and disputes regarding use and disclosure of Personal Information in accordance
        with
        the principles contained in this Website Privacy</p>
      <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">Statement.</p>
      <p style="text-indent: 0pt;text-align: left;"><br/></p>
      <p className="s3" style="padding-left: 5pt;text-indent: 0pt;text-align: left;">Changes to this Website Privacy
        Statement</p>
      <p style="padding-top: 9pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">OTB Life Science reserves the right
        to
        amend this Website Privacy Statement from time to time to reflect technological advancements, legal and regulatory
        changes, and OTB&#39;s business practices, subject to applicable laws. If OTB Life Science changes its privacy
        practices, an updated version of this Website</p>
      <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">Privacy Statement will reflect those changes. OTB
        Life
        Science will provide notice of such changes by updating the effective date listed on this Website Privacy
        Statement.
        Your continued interaction with OTB Life Science, in the activities covered above, will be subject to the
        then-current
        Website Privacy Statement.</p>
      <p style="text-indent: 0pt;text-align: left;"><br/></p>
      <h1 style="padding-left: 5pt;text-indent: 0pt;text-align: left;">Last Updated: 27 January 2020</h1>
    </div>
  `

  return (
    <div className={"p-16"}>
      <h1 className={"text-4xl font-black"}>OTB Privacy Policy</h1>
      <br/>
      {Parser().parse(rawHTML)}
    </div>
  );
  }
;

export default Privacy;

import React from "react";
import {useParams} from "react-router";
import Styled from "./styled";
import VideoPlayer from "./VideoPlayer";
import {buildPath} from "../../router";
import {ReactComponent as BackIcon} from "../../assets/arrowIcon.svg";
import {Link} from "react-router-dom";
import {useProjectStore} from "../../store/projectStore";
import {useQuery} from "../../constants/useQuery";

const Video = () => {
  const {videoIndex} = useParams();

  const {videos, project} = useProjectStore();
  const video = videos?.[videoIndex];

  const searchParams = useQuery();
  const email = searchParams.get("email");

  return (
    <div className={"md:px-[120px] md:py-[50px] p-[24px] bg-white rounded-lg shadow"}>
      <Styled.BackLink to={buildPath.videosList(project?.id, email)}>
        <BackIcon/>
        Back
      </Styled.BackLink>
      <Styled.Title>
        {video?.title}
      </Styled.Title>
      <VideoPlayer
        email={email}
        videoId={video?.id}
        material={video?.material}
        thumbnail={video?.thumbnail}
      />
      <div className={"flex w-full place-content-center"}>
      <Link to={buildPath.assessment(project?.id, videoIndex, email)}>
        <button
          className="h-14 px-[70px]
           py-4 bg-gradient-to-r from-blue-600 to-blue-400 rounded-[3px] justify-center items-center gap-2.5 inline-flex text-white text-[15px] font-normal">
          Take Assessment
          <BackIcon/>
        </button>
      </Link>
      </div>
    </div>
  );
};

export default Video;

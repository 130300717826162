import React, { InputHTMLAttributes } from "react";
import { twMerge } from "tailwind-merge";

type TextFieldProps = InputHTMLAttributes<HTMLInputElement> & { label: string };

const TextField = React.forwardRef<HTMLInputElement, TextFieldProps>((props, ref) => {
  return (
    <div className={twMerge("px-6 rounded-[3px] border border-zinc-200 relative flex flex-col justify-center", props.className)}>
      <label className={"opacity-40 text-black text-xs font-normal"}>{props.label}</label>
      <input ref={ref} {...props} type={props.type} className={"text-black text-[15px] font-normal outline-none w-full"} />
    </div>
  );
});

export default TextField;

export const getStatus = (questionID, id, answersDetails, answers) => {
  if (!answersDetails) return null;
  if (
    answersDetails?.isCorrect &&
    `${answersDetails?.correctOptionID}` === `${id}`
  ) {
    return "correctAnswer";
  }
  if (
    !answersDetails?.isCorrect &&
    // result?.passed &&
    `${id}` === `${answersDetails?.correctOptionID}`
  )
    return "correctOption";
  if (
    !answersDetails[questionID]?.isCorrect &&
    `${answers[questionID]}` === `${id}`
  )
    return "incorrectAnswer";

  return "hasResult";
};

import styled from "styled-components";
import { Link } from "react-router-dom";
import {MEDIA} from "../../constants/constants";
import Button from "../../components/Button/Button";

const Title = styled.div`
  font-weight: 600;
  font-size: 32px;
  margin-bottom: 32px;
`;

const ControlIcon = styled.div`
  position: absolute;
  left: 50%;
  top: 55%;
  transform: translate(-50%, -50%);
  width: 120px;
  height: 120px;
  border-radius: 50%;
  z-index: 1;
  background: #fff;
  opacity: 0;
  cursor: pointer;
  transition: 0.3s opacity, 0.3s top;
  img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    max-width: 40px;
  }
`;

const VideoWrapper = styled.div`
  height: auto;
  position: relative;
  background: #000;
  margin-bottom: 32px;
  border-radius: 8px;
  &:hover {
    ${ControlIcon} {
      opacity: 1;
      top: 50%;
    }
    video {
      opacity: 0.8;
      transition: 0.3s opacity;
    }
  }
  video {
    border-radius: 8px;
    width: 100%;
    opacity: 1;
  }
`;

const BackLink = styled(Link)`
  color: #0973ba;
  text-decoration: none;
  display: inline-block;
  margin-bottom: 16px;
  font-weight: bolder;
  & > * {
    display: inline-block;
    vertical-align: middle;
  }
  svg {
    transform: rotate(180deg);
    path {
      stroke: #0973ba;
    }
  }
`;

const SubmitButton = styled(Button)`
  ${MEDIA.xs} {
    width: 100%;
    text-align: center;
  }
`;

export default {
  Title,
  VideoWrapper,
  ControlIcon,
  BackLink,
  SubmitButton
};

import styled from "styled-components";
import { Link } from "react-router-dom";
import {MEDIA} from "../../constants/constants";
import Button from "../../components/Button/Button";

const Title = styled.div`
  font-weight: 600;
  font-size: 32px;
  margin-bottom: 32px;
`;

const BackLink = styled(Link)`
  color: #0973ba;
  text-decoration: none;
  display: inline-block;
  margin-bottom: 16px;
  font-weight: bolder;
  & > * {
    display: inline-block;
    vertical-align: middle;
  }
  svg {
    transform: rotate(180deg);
    path {
      stroke: #0973ba;
    }
  }
`;

const SubmitButton = styled(Button)`
  ${MEDIA.xs} {
    width: 100%;
    text-align: center;
  }
`;

export default {
  Title,
  BackLink,
  SubmitButton
};

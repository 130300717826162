import React from "react";
import Styled from "./styled";
import {buildPath} from "../../router";
import {useParams} from "react-router";
import {useQuery} from "../../constants/useQuery";
import {useProjectStore} from "../../store/projectStore";
import PlayIcon from "../../assets/playIcon.png";

const Home = () => {
  const {projectID} = useParams();

  const searchParams = useQuery();
  const email = searchParams.get("email");

  const {videos} = useProjectStore();

  return (
    <div className={"md:px-[50px] md:py-[50px] p-[24px] bg-white rounded-lg shadow"}>
      <Styled.List>
        {videos?.map((video, index) => {
          const thumbnail = video?.thumbnail?.url;
          const title = video?.title;
          return (
            <Styled.Card
              key={video?.id}
              to={buildPath.video(projectID, index, email)}
            >
              <Styled.CardThumbnail>
                <img src={thumbnail} alt="" className={'w-full h-full object-cover'}/>
                <Styled.ControlIcon>
                  <img src={PlayIcon} alt="" />
                </Styled.ControlIcon>
              </Styled.CardThumbnail>

              <Styled.CardTitle>{title}</Styled.CardTitle>
            </Styled.Card>
          );
        })}
      </Styled.List>
    </div>
  );
};

export default Home;

import React from "react";

import Styled from "./styled";
import CheckIcon from "../../../assets/checkIcon.png";

const VideoResult = ({ result, gradable }) => {
  if (!gradable)
    return (
      <div>
        <Styled.SuccessTitle>Thank you!</Styled.SuccessTitle>
      </div>
    );

  const countAnswers = Object?.values(result?.answersDetails)?.filter(
    (answer) => !!answer?.isCorrect
  )?.length;

  const countIncorrectAnswers = Object?.values(result?.answersDetails)?.filter(
      (answer) => !answer?.isCorrect
  )?.length;

  const detailsArr = Object.values(result?.answersDetails);

  return (
    <div>
      {result?.passed ? (
        <Styled.SuccessRow>
          <Styled.SuccessIcon>
            <img src={CheckIcon} />
          </Styled.SuccessIcon>
          <div>
            <Styled.SuccessTitle>Congratulation!</Styled.SuccessTitle>
            <Styled.SuccessDesc>
              You successfully passed the assessment
            </Styled.SuccessDesc>
            <Styled.SuccessScore>
              😎 {countAnswers} out of {detailsArr?.length} answers correct
            </Styled.SuccessScore>
          </div>
        </Styled.SuccessRow>
      ) : (
        <Styled.FailureRow>
          <div>
            <Styled.FailureTitle>Oh no..</Styled.FailureTitle>
            <Styled.FailureDesc>
              Unfortunately you didn't passed the assessment
            </Styled.FailureDesc>
            <Styled.FailureScore>
              🤔 {countIncorrectAnswers} out of {detailsArr?.length} answers wrong
            </Styled.FailureScore>
          </div>
        </Styled.FailureRow>
      )}
    </div>
  );
};

export default VideoResult;

import styled from "styled-components";
import { MEDIA } from "../../../constants/constants";

const SuccessRow = styled.div`
  display: flex;
  flex-direction: row;
  padding: 50px 65px;
  align-items: center;
  ${MEDIA.xs} {
    flex-direction: column;
  }
`;

const SuccessIcon = styled.div`
  width: 102px;
  height: 102px;
  border-radius: 50%;
  margin-right: 40px;
  text-align: center;
  padding-top: 20px;
  border: 2px solid #16a513;
  position: relative;
  ${MEDIA.xs} {
    margin-bottom: 16px;
  }
  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

const SuccessTitle = styled.div`
  font-size: 32px;
  font-weight: bold;
`;

const SuccessDesc = styled.div`
  margin-bottom: 8px;
  color: rgba(0, 0, 0, 0.8);
  ${MEDIA.xs} {
    margin-bottom: 16px;
    margin-top: 8px;
  }
`;

const SuccessScore = styled.div`
  padding: 10px 24px;
  background: #f4f2f6;
  border-radius: 8px;
  font-size: 18px;
  color: #16a513;
  font-weight: bold;
`;

const FailureRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 50px 65px;
  align-items: center;
`;

const FailureTitle = styled.div`
  font-weight: 600;
  font-size: 32px;
`;

const FailureDesc = styled.div`
  margin-bottom: 8px;
  color: rgba(0, 0, 0, 0.8);
  ${MEDIA.xs} {
    margin-bottom: 16px;
    margin-top: 8px;
  }
`;

const FailureScore = styled.div`
  padding: 10px 24px;
  background: #f4f2f6;
  border-radius: 8px;
  font-size: 18px;
  color: #ff8989;
  font-weight: bold;
`;

const FailureButton = styled.div`
  font-size: 18px;
  color: #ffffff;
  padding: 17px 32px;
  background: #7a00e6;
  border-radius: 40px;
  border: 1px solid #7a00e6;
  height: fit-content;
  cursor: pointer;
  &:hover {
    color: #7a00e6;
    background: #ffffff;
  }
`;

export default {
  SuccessRow,
  SuccessIcon,
  SuccessTitle,
  SuccessDesc,
  SuccessScore,
  FailureRow,
  FailureTitle,
  FailureDesc,
  FailureScore,
  FailureButton,
};

import {create} from "zustand";
import {getProject, GetProjectResponse, getVideos, Video} from "../api/api";
import {immer} from "zustand/middleware/immer";

type ProjectStore = {
    isLoading: boolean;
    project: GetProjectResponse | null;
    videos: Video[];
    getProject: (projectKey: string) => Promise<void>;
    getVideos: (projectID: number) => Promise<void>;
};

export const useProjectStore = create<ProjectStore>()(
    immer((set) => ({
        isLoading: false,
        project: null,
        videos: [],
        getProject: async (projectKey) => {
            set({isLoading: true})
            const project = await getProject(projectKey);
            set((state) => {
                state.project = project;
                state.isLoading = false;
            });
        },
        getVideos: async (projectID: number) => {
            set({isLoading: true})
            const videos = await getVideos(projectID);
            set({videos, isLoading: false});
        }
    })),
);

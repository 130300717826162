import styled from "styled-components";

const Button = styled.div`
  display: inline-block;
  color: #fff;
  background: #0973ba;
  border: 1px solid #0973ba;
  padding: 16px 32px;
  border-radius: 40px;
  line-height: 1;
  cursor: pointer;
  transition: 0.3s color, background;
  &:hover {
    color: #0973ba;
    background: #fff;
    path {
      stroke: #0973ba;
    }
  }
  &[disabled] {
    pointer-events: none;
    cursor: not-allowed;
    opacity: 0.5;
  }
  svg {
    margin-left: 16px;
  }
  & > * {
    display: inline-block;
    vertical-align: middle;
  }
`;

export default Button;

export const API_URL = "https://api2.otb-portal.com/api";

const PATH = {
  GET_PROJECT: "/projects",
  GET_VIDEO: "/videos/project",
  WATCHING_PROGRESS: "/watching-progresses/create-update",
  SUBMIT_ANSWER: "/assessment-answers",
};

export const buildAPIPath = {
  getProject: (id) => `${API_URL}${PATH.GET_PROJECT}/${id}`,
  getVideo: (id) => `${API_URL}${PATH.GET_VIDEO}/${id}`,
  watchProgress: () => `${API_URL}${PATH.WATCHING_PROGRESS}`,
  submitAnswer: () => `${API_URL}${PATH.SUBMIT_ANSWER}`,
};

export const MEDIA = {
  xlg: "@media (min-width: 1360px)",
  lg: "@media (min-width: 992px) and (max-width: 1359.98px)",
  md: "@media (min-width: 768px) and (max-width: 991.98px)",
  sm: "@media (min-width: 576px) and (max-width: 767.98px)",
  xs: "@media (max-width: 575.98px)",
  lgAndLess: "@media (max-width: 1369.98px)",
  mdAndLess: "@media (max-width: 991.98px)",
  smAndLess: "@media (max-width: 767.98px)",
};

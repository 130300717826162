import {AnswersResult, submitAnswersAPI} from "../api/api";
import {create} from "zustand";

export type AssessmentStore = {
    result: AnswersResult | null;
    submitAnswers: (data: any) => Promise<void>;
}

export const useAssessmentStore = create<AssessmentStore>()(
    (set) => ({
        result: null,
        submitAnswers: async (data: any) => {
            const result = await submitAnswersAPI(data);
            set({result});
        }
    }),
);

import React, {useEffect} from "react";
import ProfileStep from "../Profile/ProfileStep";
import {useProjectStore} from "../../store/projectStore";
import {buildPath} from "../../router";
import {useHistory} from "react-router-dom";
import {useQuery} from "../../constants/useQuery";

const Profile = () => {
  const projectKey = window.location.hostname.split('.')[0];
  const project = useProjectStore((state) => state.project);
  const getProject = useProjectStore((state) => state.getProject);
  const history = useHistory();

  const searchParams = useQuery();
  const email = searchParams.get("email");

  const onSubmit = async (email) => {
    history.push(buildPath.videosList(project.id, email))
  }

  useEffect(() => {
    if (!projectKey) return;
    getProject(projectKey);
  }, [projectKey]);

  return (
    <div>
      <ProfileStep project={project} email={email} projectKey={projectKey} onSubmit={onSubmit}/>
    </div>
  );
};

export default Profile;

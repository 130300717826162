import {Controller, useForm, useWatch} from "react-hook-form";
import {z, ZodBoolean, ZodEffects, ZodString} from "zod";
import {zodResolver} from "@hookform/resolvers/zod";
import TextField from "../../components/ui/TextField";
import * as Checkbox from "@radix-ui/react-checkbox";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRightLong} from "@fortawesome/free-solid-svg-icons";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import {GetProfileResponse, GetProjectResponse, ProfileFieldKey, profileFieldKeys} from "../../api/api";
import {useEffect, useMemo} from "react";
import {twMerge} from "tailwind-merge";
import {isEmpty, pick} from "lodash-es";
import {useProfileStore} from "../../store/profileStore";
import {LoadingOutlined} from "@ant-design/icons";
import {countries} from "countries-list";
import CountrySelect from "../../components/ui/CountrySelect";
import {requiredZodString} from "../../store";

type ProfileFormValues = Partial<Record<ProfileFieldKey, string>> & { email: string; agreed: boolean };

type ProfileProps = {
    projectKey: string;
    email: string | null;
    project: GetProjectResponse | null;
    onSubmit: (email: string) => void;
};

const ProfileStep = ({projectKey, email, project, onSubmit: onSubmitProp}: ProfileProps) => {
    const profile = useProfileStore((state) => state.profile);
    const errorMessage = useProfileStore((state) => state.errorMessage);
    const getProfile = useProfileStore((state) => state.getProfile);
    const updateProfile = useProfileStore((state) => state.updateProfile);
    const schema = useMemo(() => {
        let profileFieldsRequireMap: Record<ProfileFieldKey, boolean>;
        if (project) {
            profileFieldsRequireMap = Object.fromEntries(project.profileFields.map((field) => [field.key, field.required])) as Record<ProfileFieldKey, boolean>;
        } else {
            profileFieldsRequireMap = Object.fromEntries(profileFieldKeys.map((key) => [key, false])) as Record<ProfileFieldKey, boolean>;
        }
        return z.object<
            Record<ProfileFieldKey, ZodString> & {
            agreed: ZodEffects<ZodBoolean, boolean, boolean>;
        }
        >({
            email: requiredZodString(z.string().email().trim(), profileFieldsRequireMap["email"]),
            firstName: requiredZodString(z.string().trim(), profileFieldsRequireMap["firstName"]),
            lastName: requiredZodString(z.string().trim(), profileFieldsRequireMap["lastName"]),
            mobileNumber: requiredZodString(z.string(), profileFieldsRequireMap["mobileNumber"]),
            country: requiredZodString(z.string().trim(), profileFieldsRequireMap["country"]),
            scfhsId: requiredZodString(z.string().trim(), profileFieldsRequireMap["scfhsId"]),
            agreed: z.boolean().refine((value) => value, {message: "You didn't agree to the data privacy statement"}),
        });
    }, [project]);
    const {
        control,
        register,
        handleSubmit,
        setValue,
        formState: {errors, isSubmitting},
    } = useForm<ProfileFormValues>({
        defaultValues: {
            email: "",
            country: "United Arab Emirates",
            firstName: "",
            lastName: "",
            mobileNumber: "",
            scfhsId: "",
            agreed: false,
        },
        resolver: zodResolver(schema),
    });
    const selectedCountry = useWatch({control, name: "country"});
    const selectedCountryCode = useMemo(() => Object.entries(countries).find(([, country]) => country.name === selectedCountry)?.[0], [selectedCountry]);

    const profileFieldKeyToVisibilityMap = Object.fromEntries(
        profileFieldKeys.map((key) => (project ? [key, !!project.profileFields.find((field) => field.key === key)] : [key, false])),
    ) as Record<ProfileFieldKey, boolean>;

    const onSubmit = async (values: ProfileFormValues) => {
        const success = await updateProfile(projectKey, {
            email: email || values.email,
            info: pick(
                values,
                Object.entries(profileFieldKeyToVisibilityMap)
                    .filter(([, value]) => !!value)
                    .map(([key]) => key),
            ),
        });
        if (success) {
            onSubmitProp(email || values.email);
        }
    };

    useEffect(() => {
        if (!email) return;
        getProfile({projectKey, email});
    }, [projectKey, email]);

    useEffect(() => {
        if (!profile) return;
        if (isEmpty(profile)) {
            if (!email) return;
            setValue("email", email);
        } else {
            Object.entries((profile as GetProfileResponse).info).forEach(([key, value]) => {
                setValue(key as keyof ProfileFormValues, value);
            });
        }
    }, [profile]);

    return (
        <div className={"md:px-[120px] md:py-[70px] p-[24px] bg-white rounded-lg shadow"}>
            <div className="text-center text-black text-2xl font-medium mb-[40px]">Profile Information</div>
            {!project ? (
                <div className={"flex justify-center"}>
                    <LoadingOutlined className={"text-[30px] text-blue-600 text-center"}/>
                </div>
            ) : (
                <form onSubmit={handleSubmit(onSubmit)} noValidate>
                    <div className={"grid md:grid-cols-2 grid-cols-1 gap-[24px]"}>
                        {profileFieldKeyToVisibilityMap["email"] && (
                            <div>
                                <TextField label={"Your email"} type={"email"} {...register("email")}
                                           className={twMerge("w-full h-[50px]", errors.email && "border border-red-500")}/>
                                {errors.email &&
                                  <div className={"text-red-500 text-[15px] font-normal"}>{errors.email.message}</div>}
                            </div>
                        )}
                        {profileFieldKeyToVisibilityMap["country"] && (
                            <Controller
                                control={control}
                                name={"country"}
                                render={({field}) => <CountrySelect value={field.value as string}
                                                                    onChange={(value) => setValue("country", value as string)}
                                                                    className={"h-[50px]"}/>}
                            />
                        )}
                        {profileFieldKeyToVisibilityMap["firstName"] && (
                            <div>
                                <TextField label={"First name"} {...register("firstName")}
                                           className={twMerge("w-full h-[50px]", errors.firstName && "border border-red-500")}/>
                                {errors.firstName && <div
                                  className={"text-red-500 text-[15px] font-normal"}>{errors.firstName.message}</div>}
                            </div>
                        )}
                        {profileFieldKeyToVisibilityMap["mobileNumber"] && (
                            <div className={"h-[50px]"}>
                                <Controller
                                    control={control}
                                    name={"mobileNumber"}
                                    render={({field}) => (
                                        <PhoneInput
                                            containerClass={twMerge(
                                                "rounded-[3px] border border-zinc-200 text-black text-[15px] font-normal w-full h-full",
                                                errors.mobileNumber && "border border-red-500",
                                            )}
                                            inputStyle={{width: "100%", height: "100%", border: "none"}}
                                            country={selectedCountryCode?.toLowerCase()}
                                            specialLabel={""}
                                            value={field.value}
                                            onChange={(phone) => {
                                                setValue("mobileNumber", phone);
                                            }}
                                        />
                                    )}
                                />
                                {errors.mobileNumber && <div
                                  className={"text-red-500 text-[15px] font-normal"}>{errors.mobileNumber.message}</div>}
                            </div>
                        )}
                        {profileFieldKeyToVisibilityMap["lastName"] && (
                            <div>
                                <TextField label={"Last name"} {...register("lastName")}
                                           className={twMerge("w-full h-[50px]", errors.lastName && "border border-red-500")}/>
                                {errors.lastName && <div
                                  className={"text-red-500 text-[15px] font-normal"}>{errors.lastName.message}</div>}
                            </div>
                        )}
                        {profileFieldKeyToVisibilityMap["scfhsId"] &&
                          <TextField label={"SCFHS ID"} {...register("scfhsId")}
                                     className={twMerge("w-full h-[50px]")}/>}
                    </div>
                    <div className={"grid grid-cols-1 md:grid-cols-2 gap-[24px] mt-[32px] items-center"}>
                        <div>
                            <div className={"flex items-center"}>
                                <Controller
                                    control={control}
                                    name={"agreed"}
                                    render={({field}) => (
                                        <Checkbox.Root
                                            className="w-4 h-4 border border-blue-600 relative"
                                            id="accept-terms-and-conditions"
                                            checked={field.value}
                                            onCheckedChange={(checked) => setValue("agreed", checked as boolean)}>
                                            <Checkbox.Indicator
                                                className="w-3 h-3 left-[1px] top-[1px] absolute bg-blue-600 border border-blue-600"></Checkbox.Indicator>
                                        </Checkbox.Root>
                                    )}
                                />
                                <label className="cursor-pointer select-none pl-[8px]"
                                       htmlFor="accept-terms-and-conditions">
                                    I agree to the data privacy statement
                                </label>
                            </div>
                            {(projectKey === 'st' || projectKey === 'stada') && <div className={"text-sm text-gray-400 mt-4"}>I hereby consent to the use of my personal
                                data (name, e-mail address) by STADA Arzneimittel AG (the sponsor of this webinar) for
                                the purpose of sending offers and information by e-mail. I am aware that I can revoke
                                this consent at anytime in writing by e-mail to "<a
                                    className={"text-blue-600 visited:text-purple-600 underline"}
                                    href={"mailto:info@stada.de"}>info@stada.de</a>". further information the processing
                                of personal data by STADA can be found under <a target={"_blank"}
                                                                                className={"text-blue-600 visited:text-purple-600 underline"}
                                                                                href={"https://www.stada.com/about-stada/compliance"}>https://www.stada.com/about-stada/compliance</a>.
                            </div>}
                            {errors.agreed &&
                              <div className={"text-red-500 text-[15px] font-normal"}>{errors.agreed.message}</div>}
                        </div>
                        <div className="w-full self-start">
                            <button
                                className="h-14 px-6 py-4 bg-gradient-to-r from-blue-600 to-blue-400 rounded-[3px] w-full justify-center items-center gap-2.5 inline-flex text-white text-[15px] font-normal"
                                disabled={isSubmitting}
                                type={"submit"}>
                                {isSubmitting && <LoadingOutlined/>}
                                <span>Start</span>
                                <FontAwesomeIcon icon={faArrowRightLong}/>
                            </button>
                            <div className="w-full text-center mt-3 text-red-500">{errorMessage}</div>
                        </div>
                    </div>
                </form>
            )}
        </div>
    );
};

export default ProfileStep;

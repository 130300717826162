import {create} from "zustand";
import {getProfile, GetProfileParam, GetProfileResponse, updateProfile, UpdateProfileParam} from "../api/api";
import {immer} from "zustand/middleware/immer";
import {AxiosError} from "axios";

type ProfileStore = {
    profile: GetProfileResponse | null;
    errorMessage: string | null,
    getProfile: (param: GetProfileParam) => Promise<void>;
    updateProfile: (projectKey: string, param: UpdateProfileParam) => Promise<boolean>;
};

export const useProfileStore = create<ProfileStore>()(
    immer((set) => ({
        profile: null,
        errorMessage: null,
        getProfile: async (param) => {
            try {
                const profile = await getProfile(param);
                set((state) => {
                    state.profile = profile;
                });
            } catch (e) {
                if(e instanceof AxiosError) {
                    set({errorMessage: e?.response?.data?.error?.message})
                } else {
                    set({errorMessage: "Failed to load profile, please contact us for more information"})
                }
            }
        },
        updateProfile: async (projectKey, param) => {
            try {
                const updatedProfile = await updateProfile(projectKey, param);
                set((state) => {
                    state.profile = updatedProfile;
                });
                return true;
            } catch (e) {
                if(e instanceof AxiosError) {
                    set({errorMessage: e?.response?.data?.error?.message})
                    return false;
                } else {
                    set({errorMessage: "Failed to load profile, please contact us for more information"})
                    return false;
                }
            }
        },
    })),
);

import axiosInstance from "../api/axiosInstance";

export const profileFieldKeys = ["firstName", "lastName", "email", "country", "mobileNumber", "scfhsId"] as const;
export type ProfileFieldKey = (typeof profileFieldKeys)[number];

export type ProfileField = {
  id: number;
  key: ProfileFieldKey;
  required: boolean;
};

type LogoFormat = {
  ext: string;
  url: string;
  hash: string;
  mime: string;
  name: string;
  path: string | null;
  size: number;
  width: number;
  height: number;
};

type Logo = {
  id: number;
  name: string;
  alternativeText: string | null;
  caption: string | null;
  width: number;
  height: number;
  formats: Partial<Record<"small" | "medium" | "thumbnail", LogoFormat>>;
  hash: string;
  ext: string;
  mime: string;
  size: number;
  url: string;
  previewUrl: string | null;
  provider: string;
  provider_metadata: null;
  folderPath: string;
  createdAt: string;
  updatedAt: string;
};

export type Accreditation = {
  id: number;
  points: number;
  accreditation: AccreditationData
};

export type AccreditationData = {
  id: number;
  title: string;
  templateName: string;
  createdAt: string;
  updatedAt: string;
  logo: Logo;
};

type ExternalLink = { id: number; title: string; link: string };

export type GetProjectResponse = {
  id: number;
  name: string;
  createdAt: string;
  updatedAt: string;
  key: string;
  profileFields: ProfileField[];
  logo: Logo;
  accreditations: Accreditation[];
  externalLinks: ExternalLink[];
};

export const getProject = (projectKey: string) => {
  return axiosInstance.get<GetProjectResponse>(`/projects/key/${projectKey}`).then((res) => res.data);
};

export type GetProfileParam = {
  projectKey: string;
  email: string;
};

export type GetProfileResponse = {
  id: number;
  email: string;
  info: Partial<Record<ProfileFieldKey, string>>;
  createdAt: string;
  updatedAt: string;
};

export const getProfile = ({ projectKey, email }: GetProfileParam) => {
  return axiosInstance.get<GetProfileResponse>(`/profile/${projectKey}/${email}`).then((res) => res.data);
};

export type UpdateProfileParam = {
  email: string;
  info: Partial<Record<ProfileFieldKey, string>>;
};

export const updateProfile = (projectKey: string, updatedProfile: UpdateProfileParam) => {
  return axiosInstance.post<GetProfileResponse>(`/profile/creat-update/${projectKey}`, updatedProfile).then((res) => res.data);
};

type SendAccreditationParam = {
  projectKey: string;
  email: string;
  accreditationID: number;
  mode: "download" | "email";
};

export const sendAccreditation = ({ projectKey, accreditationID, email, mode }: SendAccreditationParam) => {
  return axiosInstance.get(`/project/${projectKey}/profile/${email}/accreditation/${accreditationID}?mode=${mode}`).then((res) => res.data);
};

type Material = {
  url: string;
}

type Thumbnail = {
  url: string;
}

type QuestionOption = {
  id: number;
  option: string;
}

type Question = {
  id: number;
  title: string;
  type: string;
  options: QuestionOption[];
}

type Assessment = {
  id: number;
  title: string;
  gradable: boolean;
  successScore: number;
  createdAt: string;
  updatedAt: string;
  questions: Question[];
}

export type Video = {
  id: number;
  title: string;
  createdAt: string;
  updatedAt: string;
  material: Material;
  thumbnail: Thumbnail;
  assessment: Assessment;
}

export const getVideos = (projectID: number) => {
  return axiosInstance.get<Video[]>(`/videos/project/${projectID}`).then((res) => res.data);
};


export type AnswerDetails = {
  [questionId: string]: {
    isCorrect: boolean;
    correctOptionID: number;
  };
}

export type AnswersResult = {
  score: string;
  answersDetails: AnswerDetails;
  passed: boolean;
  requiredScore: number;
}

export const submitAnswersAPI = (data: any) => {
  return axiosInstance.post<AnswersResult>(`/assessment-answers`, data).then((res) => res.data);
};
